<template>
  <span class="move-streets" v-if="hasPerm('people.change_street')">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onSave"
      :ok-disabled="formInvalid"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Changer"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-house-chimney"></i>
          Changer <counter-label :counter="streets.length" label="rue"></counter-label> de quartier
        </b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div v-if="streets && streets.length" style="padding: 5px; background: #222; color: #fff; margin-bottom: 20px">
        Sélectionnez le quartier dans lequel affecter
        <span v-if="streets.length > 1">
          les {{ streets.length }} rues sélectionnées
        </span>
        <span v-else>
          {{ streets[0].name }}
        </span>
      </div>
      <b-row>
        <b-col>
          <b-form-group label="Quartier actuel">
            <b-input type="text" disabled v-if="currentGroup" :value="currentGroup.name"></b-input>
            <b-input type="text" disabled v-else value="Quartier non défini"></b-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="selectedGroup-gr"
            label="Changer vers"
            label-for="selectedGroup"
          >
            <b-input-group>
              <b-form-select
                v-model="selectedGroup"
                id="selectedGroup"
                v-if="!newStreetGroup"
              >
              <b-form-select-option
                :value="elt"
                v-for="elt in groups"
                :key="elt.id"
              >
                {{ elt.name }}
                </b-form-select-option>
              </b-form-select>
              <b-form-input
                v-model="newStreetGroupName"
                id="newStreetGroupName"
                v-if="newStreetGroup"
              >
              </b-form-input>
              <b-input-group-append v-if="canAddStreetGroup">
                <b-button
                  variant="secondary"
                  @click.prevent="switchStreetGroup"
                  :title="newStreetGroup ? 'Choisir depuis la liste des quartiers' : 'Ajouter un quartier'"
                  v-b-tooltip
                >
                  <i class="fa fa-plus" v-if="!newStreetGroup"></i>
                  <i class="fa fa-list-dots" v-else></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeChoice } from '@/types/base'

export default {
  name: 'MoveStreetsModal',
  components: { CounterLabel, },
  mixins: [BackendMixin],
  props: {
    currentGroup: Object,
    groups: Array,
    streets: Array,
  },
  data() {
    return {
      errorText: '',
      selectedGroup: null,
      newStreetGroup: false,
      newStreetGroupName: '',
    }
  },
  watch: {
    groups: function() {
      this.init()
    },
    street: function() {
      if (this.street) {
        this.init()
      }
    },
  },
  mounted() {
    this.init()
  },
  computed: {
    canAddStreetGroup() {
      return this.hasPerm('people.add_streetgroup')
    },
    formInvalid() {
      if (this.streets.length === 0) {
        return false
      }
      if (this.newStreetGroup) {
        return !this.newStreetGroupName
      } else {
        return (this.selectedGroup === null)
      }
    },
    modalId() {
      return 'bv-modal-move-street'
    },
  },
  methods: {
    ...mapActions(['addSuccess']),
    init() {
    },
    async onSave() {
      this.errorText = ''
      if (!this.formInvalid) {
        let url = '/api/people/change-streets/'
        const backendApi = new BackendApi('post', url)
        try {
          const data = {
            'streets': this.streets.map(elt => elt.id),
          }
          if (this.newStreetGroup) {
            data.group = 0
            data['group_name'] = this.newStreetGroupName
          } else {
            data.group = this.selectedGroup.id
          }
          const resp = await backendApi.callApi(data)
          const theGroup = makeChoice(resp.data.group)
          const groupName = this.newStreetGroup ? this.newStreetGroupName : this.selectedGroup.name
          let text = ''
          if (this.streets.length === 1) {
            text = 'La rue a été placée dans le quartier ' + groupName
          } else {
            text = 'Les rues ont été placées dans le quartier ' + groupName
          }
          await this.addSuccess(text)
          this.$bvModal.hide(this.modalId)
          this.$emit('done', { group: theGroup, new: this.newStreetGroup, })
          this.selectedGroup = null
          this.newStreetGroupName = ''
          this.newStreetGroup = false
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
    switchStreetGroup() {
      this.newStreetGroup = !this.newStreetGroup
    },
  },
}
</script>

<style scoped>
</style>
